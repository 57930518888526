/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "brandguide.report.proto" (package "brandguidereport", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * easyjson:json
 *
 * @generated from protobuf message brandguidereport.Result
 */
export interface Result {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string brand_id = 2 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: string brand_description = 3 [json_name = "brand_description"];
     */
    brand_description: string;
    /**
     * @generated from protobuf field: string account_id = 4 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string account_email = 5 [json_name = "account_email"];
     */
    account_email: string;
    /**
     * @generated from protobuf field: string profile_id = 6 [json_name = "profile_id"];
     */
    profile_id: string;
    /**
     * @generated from protobuf field: string profile_email = 7 [json_name = "profile_email"];
     */
    profile_email: string;
    /**
     * @generated from protobuf field: string created_at = 8 [json_name = "created_at"];
     */
    created_at: string;
}
/**
 * @generated from protobuf message brandguidereport.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: repeated brandguidereport.Result created = 1;
     */
    created: Result[];
    /**
     * @generated from protobuf field: repeated brandguidereport.Result saved = 2;
     */
    saved: Result[];
    /**
     * @generated from protobuf field: repeated brandguidereport.Result returned = 3;
     */
    returned: Result[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Result$Type extends MessageType<Result> {
    constructor() {
        super("brandguidereport.Result", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_description", kind: "scalar", localName: "brand_description", jsonName: "brand_description", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "account_email", kind: "scalar", localName: "account_email", jsonName: "account_email", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "profile_id", kind: "scalar", localName: "profile_id", jsonName: "profile_id", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "profile_email", kind: "scalar", localName: "profile_email", jsonName: "profile_email", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Result>): Result {
        const message = { version: "", brand_id: "", brand_description: "", account_id: "", account_email: "", profile_id: "", profile_email: "", created_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Result>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Result): Result {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version */ 1:
                    message.version = reader.string();
                    break;
                case /* string brand_id = 2 [json_name = "brand_id"];*/ 2:
                    message.brand_id = reader.string();
                    break;
                case /* string brand_description = 3 [json_name = "brand_description"];*/ 3:
                    message.brand_description = reader.string();
                    break;
                case /* string account_id = 4 [json_name = "account_id"];*/ 4:
                    message.account_id = reader.string();
                    break;
                case /* string account_email = 5 [json_name = "account_email"];*/ 5:
                    message.account_email = reader.string();
                    break;
                case /* string profile_id = 6 [json_name = "profile_id"];*/ 6:
                    message.profile_id = reader.string();
                    break;
                case /* string profile_email = 7 [json_name = "profile_email"];*/ 7:
                    message.profile_email = reader.string();
                    break;
                case /* string created_at = 8 [json_name = "created_at"];*/ 8:
                    message.created_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Result, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version = 1; */
        if (message.version !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.version);
        /* string brand_id = 2 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand_id);
        /* string brand_description = 3 [json_name = "brand_description"]; */
        if (message.brand_description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_description);
        /* string account_id = 4 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.account_id);
        /* string account_email = 5 [json_name = "account_email"]; */
        if (message.account_email !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.account_email);
        /* string profile_id = 6 [json_name = "profile_id"]; */
        if (message.profile_id !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.profile_id);
        /* string profile_email = 7 [json_name = "profile_email"]; */
        if (message.profile_email !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.profile_email);
        /* string created_at = 8 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.created_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguidereport.Result
 */
export const Result = new Result$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("brandguidereport.SearchResponse", [
            { no: 1, name: "created", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Result },
            { no: 2, name: "saved", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Result },
            { no: 3, name: "returned", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Result }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { created: [], saved: [], returned: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated brandguidereport.Result created */ 1:
                    message.created.push(Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguidereport.Result saved */ 2:
                    message.saved.push(Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated brandguidereport.Result returned */ 3:
                    message.returned.push(Result.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated brandguidereport.Result created = 1; */
        for (let i = 0; i < message.created.length; i++)
            Result.internalBinaryWrite(message.created[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguidereport.Result saved = 2; */
        for (let i = 0; i < message.saved.length; i++)
            Result.internalBinaryWrite(message.saved[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated brandguidereport.Result returned = 3; */
        for (let i = 0; i < message.returned.length; i++)
            Result.internalBinaryWrite(message.returned[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message brandguidereport.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
