import * as charts from "charts"
import * as layouts from "layouts"

export interface chartData {
	[date: string]: number
}

export interface chart {
	axisID: string
	axisName: string
	position: charts.CartesianPosition
	borderColor: string
	backgroundColor: string
	labels: string[]
	data: chartData
}

export interface metric {
	title: string
	val: number
	prevVal: number
	chart: chart
}

export function parsedChartZero(proto: Partial<chart> = {}): chart {
	return {
		axisID: "",
		axisName: "",
		position: "left",
		borderColor: "",
		backgroundColor: "",
		labels: [],
		data: {},
		...proto,
	}
}

export function parsedMetricZero(proto: Partial<metric> = {}): metric {
	return {
		title: "",
		val: 0,
		prevVal: 0,
		chart: parsedChartZero(),
		...proto,
	}
}

interface props {
	labels: string[]
	dataset: metric[]
}

function scaleY(m: metric): charts.CartesianScaleOptions {
	return {
		type: "linear" as const,
		display: true,
		position: m.chart.position,
		min: 0,
		ticks: {
			color: layouts.theme.colors.grey.light30,
			maxTicksLimit: 5,
		} as charts.CartesianTickOptions,
		title: {
			display: true,
			text: m.chart.axisName,
			color: layouts.theme.colors.grey.light30,
			font: {
				size: 14,
			},
			padding: 0,
			align: "center",
		},
		grid: {
			display: true,
		} as unknown as charts.GridLineOptions,
	} as unknown as charts.CartesianScaleOptions
}

export function Chart(props: props): JSX.Element {
	const { labels, dataset } = props

	const scales = dataset.reduce(
		(scales, m) => {
			scales[m.chart.axisID] = scaleY(m)
			return scales
		},
		{
			xAxes: {
				ticks: {
					color: layouts.theme.colors.grey.light30,
				},
				grid: {
					display: false,
				},
			} as unknown as charts.CartesianScaleOptions,
		} as { [key: string]: charts.CartesianScaleOptions },
	)

	const dset = dataset.map((m) => ({
		yAxisID: m.chart.axisID,
		fill: true,
		label: m.title,
		data: Object.values(m.chart.data),
		borderColor: m.chart.borderColor,
		backgroundColor: m.chart.backgroundColor,
		tension: 0.2,
	}))

	const options = {
		maintainAspectRatio: false,
		scales: scales,
		layout: {
			padding: 20,
		},
		elements: {
			point: {
				radius: 0,
				pointHoverRadius: 5,
			},
		},
		plugins: {
			legend: {
				position: "top" as const,
				align: "end" as const,
				labels: {
					usePointStyle: true,
					boxWidth: 50,
					font: {
						size: 10,
						weight: "bold",
					},
				},
			},
		},
	}

	const data = {
		labels: labels,
		datasets: dset,
	}

	return <charts.Bar options={options} data={data} />
}
