/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "meta.account.proto" (package "meta", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message meta.Account
 */
export interface Account {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string created_at = 3 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 4 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string disabled_at = 5 [json_name = "disabled_at"];
     */
    disabled_at: string;
    /**
     * @generated from protobuf field: string email = 6;
     */
    email: string;
    /**
     * @generated from protobuf field: string phone = 7;
     */
    phone: string;
    /**
     * @generated from protobuf field: string domain = 8;
     */
    domain: string;
}
/**
 * @generated from protobuf message meta.UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    /**
     * @generated from protobuf field: meta.Account account = 1;
     */
    account?: Account;
}
/**
 * @generated from protobuf message meta.UpdateAccountResponse
 */
export interface UpdateAccountResponse {
    /**
     * @generated from protobuf field: meta.Account account = 1;
     */
    account?: Account;
}
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType<Account> {
    constructor() {
        super("meta.Account", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "disabled_at", kind: "scalar", localName: "disabled_at", jsonName: "disabled_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Account>): Account {
        const message = { id: "", description: "", created_at: "", updated_at: "", disabled_at: "", email: "", phone: "", domain: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account): Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string created_at = 3 [json_name = "created_at"];*/ 3:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 4 [json_name = "updated_at"];*/ 4:
                    message.updated_at = reader.string();
                    break;
                case /* string disabled_at = 5 [json_name = "disabled_at"];*/ 5:
                    message.disabled_at = reader.string();
                    break;
                case /* string email */ 6:
                    message.email = reader.string();
                    break;
                case /* string phone */ 7:
                    message.phone = reader.string();
                    break;
                case /* string domain */ 8:
                    message.domain = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string created_at = 3 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 4 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.updated_at);
        /* string disabled_at = 5 [json_name = "disabled_at"]; */
        if (message.disabled_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.disabled_at);
        /* string email = 6; */
        if (message.email !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.email);
        /* string phone = 7; */
        if (message.phone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phone);
        /* string domain = 8; */
        if (message.domain !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.domain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccountRequest$Type extends MessageType<UpdateAccountRequest> {
    constructor() {
        super("meta.UpdateAccountRequest", [
            { no: 1, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<UpdateAccountRequest>): UpdateAccountRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccountRequest): UpdateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Account account */ 1:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Account account = 1; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateAccountRequest
 */
export const UpdateAccountRequest = new UpdateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAccountResponse$Type extends MessageType<UpdateAccountResponse> {
    constructor() {
        super("meta.UpdateAccountResponse", [
            { no: 1, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<UpdateAccountResponse>): UpdateAccountResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateAccountResponse): UpdateAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.Account account */ 1:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.Account account = 1; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message meta.UpdateAccountResponse
 */
export const UpdateAccountResponse = new UpdateAccountResponse$Type();
