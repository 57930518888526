import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import * as layouts from "layouts"
import * as dashboard from "./dashboard"
import * as routing from "admin/routing"
import * as admin from "."

export default function Routing(props: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<admin.protector.Node>
			<layouts.containers.flex className="brands" flex="1" overflowY="hidden">
				<layouts.containers.flex className="body-container" flex="1 1" background={layouts.theme.backgrounds.bluealpha}>
					<admin.caching.Node>
						<Routes>
							<Route path="/accounts/:aid" element={<admin.accounts.Display />} />
							<Route path="/profiles/:pid" element={<admin.profiles.Display />} />
							<Route path="/brands/:bid" element={<admin.brands.Display />} />
							<Route path="/dashboard" element={<dashboard.Display />} />
							<Route path="*" element={<Navigate to={routing.dashboard()} />} />
						</Routes>
					</admin.caching.Node>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</admin.protector.Node>
	)
}
