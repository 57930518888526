import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/browser"
import { CaptureConsole } from "@sentry/integrations"
import * as sessions from "sessions"
import * as sso from "sso"
import * as brands from "brands"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as u12t from "meta/usermanagement"
import * as authz from "authz"
import * as debugx from "x/debugx"
import * as caching from "caching"
import * as authzcached from "authzcached"
import * as ftux from "ftux"
import * as oauth2 from "meta/oauth2"
import SuperTokens from "supertokens-web-js"
import ThirdParty from "supertokens-web-js/recipe/thirdparty"
import Session from "supertokens-web-js/recipe/session"
import Passwordless from "supertokens-web-js/recipe/passwordless"
import { ThemeProvider } from "@emotion/react"
import { Storage as AccountSettingsStorage } from "accounts/settings"
import { Standard as Navigation } from "navigation"
import * as admin from "admin"

function App(): JSX.Element {
	const [_session, setSession] = useState(sessions.zero(sessions.options.latestlogincreds))
	const session = sessions.zero(sessions.options.clone(_session), sessions.options.replacer(setSession))

	// detect alpha flag from location
	debugx.alpha.autodetecturl(window.location)

	SuperTokens.init({
		appInfo: {
			apiDomain: httpx.urlstorage.host(),
			apiBasePath: "/authn/supertokens",
			appName: "Brandguard",
		},
		recipeList: [
			Session.init({
				tokenTransferMethod: "header",
			}),
			Passwordless.init(),
			ThirdParty.init(),
		],
	})

	httpx // global request handlers.
		.intercept(
			// httpx.debugrequest,
			httpx.unauthenticatedrequest((cause: unknown) => {
				console.log("unauthenticated response", cause)
				setSession(sessions.zero())
				return cause as Response
			}),
		)

	if (process.env.REACT_APP_SENTRY_DSN) {
		Sentry.init({
			environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
			release: process.env.REACT_APP_SENTRY_RELEASE,
			dsn: process.env.REACT_APP_SENTRY_DSN,
			tracesSampleRate: 1.0,
			replaysOnErrorSampleRate: 1.0,
			integrations: [
				new BrowserTracing(),
				new CaptureConsole({
					levels: ["error"],
				}),
			],
		})
	}

	return (
		<ThemeProvider theme={layouts.theme.autodetect()}>
			<caching.Autoflush>
				<sessions.Provider value={session}>
					<Router>
						<sso.Routes>
							<sessions.Authenticated>
								<authzcached.Storage>
									<AccountSettingsStorage>
										<sessions.Appcues>
											<React.StrictMode>
												<layouts.modals.Root className="global-modal" width="100vw" height="100vh">
													<Navigation>
														<Routes>
															<Route path="/oauth2/*" element={<oauth2.AuthorizationRoutes />} />
															<Route path="/setup" element={<ftux.Container />} />
															<Route path="/me/debug" element={<debugx.Debug />} />
															<Route path="/brands/*" element={<brands.Routes />} />
															<Route path="/u12t/*" element={<u12t.Routes />} />
															<Route path="/authz/*" element={<authz.Routes />} />
															<Route path="/admin/*" element={<admin.Routes />} />
															<Route path="*" element={<Navigate to="/brands/unknown" replace />} />
														</Routes>
													</Navigation>
												</layouts.modals.Root>
											</React.StrictMode>
										</sessions.Appcues>
									</AccountSettingsStorage>
								</authzcached.Storage>
							</sessions.Authenticated>
						</sso.Routes>
					</Router>
				</sessions.Provider>
			</caching.Autoflush>
		</ThemeProvider>
	)
}

export default App
