export function dashboard(): string {
	return "/admin/dashboard"
}

export function accounts(aid: string): string {
	return `/admin/accounts/${aid}`
}

export function profiles(pid: string): string {
	return `/admin/profiles/${pid}`
}

export function brands(bid: string): string {
	return `/admin/brands/${bid}`
}
