/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "account.brand.proto" (package "account", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message account.Brand
 */
export interface Brand {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
    /**
     * @generated from protobuf field: string domain = 3;
     */
    domain: string;
    /**
     * @generated from protobuf field: string created_at = 4 [json_name = "created_at"];
     */
    created_at: string;
    /**
     * @generated from protobuf field: string updated_at = 5 [json_name = "updated_at"];
     */
    updated_at: string;
    /**
     * @generated from protobuf field: string account_id = 6 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: int32 brandguard_threshold = 7 [json_name = "brandguard_threshold"];
     */
    brandguard_threshold: number;
    /**
     * @generated from protobuf field: string cloned_at = 8 [json_name = "cloned_at"];
     */
    cloned_at: string;
}
/**
 * @generated from protobuf message account.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: string query = 1;
     */
    query: string;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
    /**
     * @generated from protobuf field: int32 offset = 4;
     */
    offset: number;
}
/**
 * @generated from protobuf message account.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: repeated account.Brand items = 1;
     */
    items: Brand[];
    /**
     * @generated from protobuf field: account.SearchRequest next = 2;
     */
    next?: SearchRequest;
}
/**
 * @generated from protobuf message account.CreateRequest
 */
export interface CreateRequest {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.CreateResponse
 */
export interface CreateResponse {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.UpdateRequest
 */
export interface UpdateRequest {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.UpdateResponse
 */
export interface UpdateResponse {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.DeleteResponse
 */
export interface DeleteResponse {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.CloneRequest
 */
export interface CloneRequest {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
/**
 * @generated from protobuf message account.CloneResponse
 */
export interface CloneResponse {
    /**
     * @generated from protobuf field: account.Brand brand = 1;
     */
    brand?: Brand;
}
// @generated message type with reflection information, may provide speed optimized methods
class Brand$Type extends MessageType<Brand> {
    constructor() {
        super("account.Brand", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_at", kind: "scalar", localName: "created_at", jsonName: "created_at", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "updated_at", kind: "scalar", localName: "updated_at", jsonName: "updated_at", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "brandguard_threshold", kind: "scalar", localName: "brandguard_threshold", jsonName: "brandguard_threshold", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "cloned_at", kind: "scalar", localName: "cloned_at", jsonName: "cloned_at", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Brand>): Brand {
        const message = { id: "", description: "", domain: "", created_at: "", updated_at: "", account_id: "", brandguard_threshold: 0, cloned_at: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Brand>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Brand): Brand {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string domain */ 3:
                    message.domain = reader.string();
                    break;
                case /* string created_at = 4 [json_name = "created_at"];*/ 4:
                    message.created_at = reader.string();
                    break;
                case /* string updated_at = 5 [json_name = "updated_at"];*/ 5:
                    message.updated_at = reader.string();
                    break;
                case /* string account_id = 6 [json_name = "account_id"];*/ 6:
                    message.account_id = reader.string();
                    break;
                case /* int32 brandguard_threshold = 7 [json_name = "brandguard_threshold"];*/ 7:
                    message.brandguard_threshold = reader.int32();
                    break;
                case /* string cloned_at = 8 [json_name = "cloned_at"];*/ 8:
                    message.cloned_at = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Brand, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string domain = 3; */
        if (message.domain !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.domain);
        /* string created_at = 4 [json_name = "created_at"]; */
        if (message.created_at !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.created_at);
        /* string updated_at = 5 [json_name = "updated_at"]; */
        if (message.updated_at !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.updated_at);
        /* string account_id = 6 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.account_id);
        /* int32 brandguard_threshold = 7 [json_name = "brandguard_threshold"]; */
        if (message.brandguard_threshold !== 0)
            writer.tag(7, WireType.Varint).int32(message.brandguard_threshold);
        /* string cloned_at = 8 [json_name = "cloned_at"]; */
        if (message.cloned_at !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.cloned_at);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.Brand
 */
export const Brand = new Brand$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("account.SearchRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { query: "", limit: 0, offset: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                case /* int32 offset */ 4:
                    message.offset = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        /* int32 offset = 4; */
        if (message.offset !== 0)
            writer.tag(4, WireType.Varint).int32(message.offset);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("account.SearchResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Brand },
            { no: 2, name: "next", kind: "message", T: () => SearchRequest }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated account.Brand items */ 1:
                    message.items.push(Brand.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* account.SearchRequest next */ 2:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated account.Brand items = 1; */
        for (let i = 0; i < message.items.length; i++)
            Brand.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* account.SearchRequest next = 2; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRequest$Type extends MessageType<CreateRequest> {
    constructor() {
        super("account.CreateRequest", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<CreateRequest>): CreateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRequest): CreateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.CreateRequest
 */
export const CreateRequest = new CreateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateResponse$Type extends MessageType<CreateResponse> {
    constructor() {
        super("account.CreateResponse", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<CreateResponse>): CreateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateResponse): CreateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.CreateResponse
 */
export const CreateResponse = new CreateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateRequest$Type extends MessageType<UpdateRequest> {
    constructor() {
        super("account.UpdateRequest", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<UpdateRequest>): UpdateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateRequest): UpdateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.UpdateRequest
 */
export const UpdateRequest = new UpdateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateResponse$Type extends MessageType<UpdateResponse> {
    constructor() {
        super("account.UpdateResponse", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<UpdateResponse>): UpdateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateResponse): UpdateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.UpdateResponse
 */
export const UpdateResponse = new UpdateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteResponse$Type extends MessageType<DeleteResponse> {
    constructor() {
        super("account.DeleteResponse", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<DeleteResponse>): DeleteResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteResponse): DeleteResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.DeleteResponse
 */
export const DeleteResponse = new DeleteResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloneRequest$Type extends MessageType<CloneRequest> {
    constructor() {
        super("account.CloneRequest", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<CloneRequest>): CloneRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloneRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloneRequest): CloneRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloneRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.CloneRequest
 */
export const CloneRequest = new CloneRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloneResponse$Type extends MessageType<CloneResponse> {
    constructor() {
        super("account.CloneResponse", [
            { no: 1, name: "brand", kind: "message", T: () => Brand }
        ]);
    }
    create(value?: PartialMessage<CloneResponse>): CloneResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CloneResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloneResponse): CloneResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* account.Brand brand */ 1:
                    message.brand = Brand.internalBinaryRead(reader, reader.uint32(), options, message.brand);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloneResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* account.Brand brand = 1; */
        if (message.brand)
            Brand.internalBinaryWrite(message.brand, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message account.CloneResponse
 */
export const CloneResponse = new CloneResponse$Type();
