import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as layouts from "layouts"
import * as api from "admin/api"
import * as components from "admin/dashboard/components"
import * as typography from "typography"
import * as icons from "icons"
import { Link } from "react-router-dom"
import * as routing from "admin/routing"
import * as admin from "admin"

interface props extends layouts.containers.FlexProps {
	req: api.SearchRequest
}

export default function List(props: props): JSX.Element {
	const { req, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState(api.dashboard.zeros.bgb_response())

	useEffect(() => {
		if (req.range?.oldest === components.neginfiso || req.range?.newest === components.infiso) return
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => api.dashboard.bgb_report(req, authzc.bearer(metaauthz)))
			.then(setData)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve profiles</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [req])

	return (
		<layouts.Flex className="accounts" flexDirection="column" flex="1" {...rest}>
			<errors.overlay styled cause={cause}>
				<layouts.loading.pending loading={loading}>
					<layouts.Flex flexDirection="column" gridGap="50px" fontSize="12px">
						<layouts.Flex flexDirection="column">
							<layouts.Flex px="10px">
								<typography.h5 lineHeight="20px">BGB Guides: New</typography.h5>
							</layouts.Flex>
							<layouts.tables.container>
								<table style={{ color: layouts.theme.colors.grey.dark20 }}>
									<thead>
										<tr>
											<components.layouts.TableHead>BrandID</components.layouts.TableHead>
											<components.layouts.TableHead>Brand Desc</components.layouts.TableHead>
											<components.layouts.TableHead>AccountID</components.layouts.TableHead>
											<components.layouts.TableHead>Account Email</components.layouts.TableHead>
											<components.layouts.TableHead>ProfileID</components.layouts.TableHead>
											<components.layouts.TableHead>Profile Email</components.layouts.TableHead>
											<components.layouts.TableHead>Created At</components.layouts.TableHead>
										</tr>
									</thead>
									<tbody>
										{data.created.map((b, i) => (
											<React.Fragment key={i}>
												<components.layouts.TableRow>
													<components.layouts.TableData>
														<Link to={admin.routing.brands(b.brand_id)}>{b.brand_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.brand_description}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.accounts(b.account_id)}>{b.account_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.account_email}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.profiles(b.profile_id)}>{b.profile_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.profile_email}</components.layouts.TableData>
													<components.layouts.TableData>
														{layouts.tables.Timestamp(b.created_at)}
													</components.layouts.TableData>
												</components.layouts.TableRow>
											</React.Fragment>
										))}
									</tbody>
								</table>
							</layouts.tables.container>
						</layouts.Flex>
						<layouts.Flex flexDirection="column">
							<layouts.Flex px="10px">
								<typography.h5 lineHeight="20px">BGB Guides: Saved PDF </typography.h5>
							</layouts.Flex>
							<layouts.tables.container>
								<table style={{ color: layouts.theme.colors.grey.dark20 }}>
									<thead>
										<tr>
											<components.layouts.TableHead>BrandID</components.layouts.TableHead>
											<components.layouts.TableHead>Brand Desc</components.layouts.TableHead>
											<components.layouts.TableHead>AccountID</components.layouts.TableHead>
											<components.layouts.TableHead>Account Email</components.layouts.TableHead>
											<components.layouts.TableHead>ProfileID</components.layouts.TableHead>
											<components.layouts.TableHead>Profile Email</components.layouts.TableHead>
											<components.layouts.TableHead>Created At</components.layouts.TableHead>
											<components.layouts.TableHead>PDF</components.layouts.TableHead>
										</tr>
									</thead>
									<tbody>
										{data.saved.map((b, i) => (
											<React.Fragment key={i}>
												<components.layouts.TableRow>
													<components.layouts.TableData>
														<Link to={admin.routing.brands(b.brand_id)}>{b.brand_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.brand_description}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.accounts(b.account_id)}>{b.account_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.account_email}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.profiles(b.profile_id)}>{b.profile_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.profile_email}</components.layouts.TableData>
													<components.layouts.TableData>
														{layouts.tables.Timestamp(b.created_at)}
													</components.layouts.TableData>
													<components.layouts.TableData>
														<layouts.Flex
															title="Download"
															onClick={() => {
																const req = {
																	version: b.version,
																	account_id: b.account_id,
																	brand_id: b.brand_id,
																} as api.PDFRequest
																const retry = httpx.autoretry()
																retry
																	.wrap(() => api.dashboard.get_pdf(req, authzc.bearer(metaauthz)))
																	.then((r) => {
																		return r.blob()
																	})
																	.then((b) => {
																		download(b)
																	})
															}}
														>
															<icons.pdf.download
																width="30px"
																height="30px"
																stroke={layouts.theme.colors.grey.medium}
															/>
														</layouts.Flex>
													</components.layouts.TableData>
												</components.layouts.TableRow>
											</React.Fragment>
										))}
									</tbody>
								</table>
							</layouts.tables.container>
						</layouts.Flex>
						<layouts.Flex flexDirection="column">
							<layouts.Flex px="10px">
								<typography.h5 lineHeight="20px">BGB Guides: Returned to progress </typography.h5>
							</layouts.Flex>
							<layouts.tables.container>
								<table style={{ color: layouts.theme.colors.grey.dark20 }}>
									<thead>
										<tr>
											<components.layouts.TableHead>BrandID</components.layouts.TableHead>
											<components.layouts.TableHead>Brand Desc</components.layouts.TableHead>
											<components.layouts.TableHead>AccountID</components.layouts.TableHead>
											<components.layouts.TableHead>Account Email</components.layouts.TableHead>
											<components.layouts.TableHead>ProfileID</components.layouts.TableHead>
											<components.layouts.TableHead>Profile Email</components.layouts.TableHead>
											<components.layouts.TableHead>Created At</components.layouts.TableHead>
										</tr>
									</thead>
									<tbody>
										{data.returned.map((b, i) => (
											<React.Fragment key={i}>
												<components.layouts.TableRow>
													<components.layouts.TableData>
														<Link to={admin.routing.brands(b.brand_id)}>{b.brand_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.brand_description}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.accounts(b.account_id)}>{b.account_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.account_email}</components.layouts.TableData>
													<components.layouts.TableData>
														<Link to={routing.profiles(b.profile_id)}>{b.profile_id}</Link>
													</components.layouts.TableData>
													<components.layouts.TableData>{b.profile_email}</components.layouts.TableData>
													<components.layouts.TableData>
														{layouts.tables.Timestamp(b.created_at)}
													</components.layouts.TableData>
												</components.layouts.TableRow>
											</React.Fragment>
										))}
									</tbody>
								</table>
							</layouts.tables.container>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.loading.pending>
			</errors.overlay>
		</layouts.Flex>
	)
}

const download = (d: Blob) => {
	const url = window.URL.createObjectURL(new Blob([d]))
	const link = document.createElement("a")
	link.href = url
	link.setAttribute("download", "brandguide.pdf")
	document.body.appendChild(link)
	link.click()
	link.remove()
	window.URL.revokeObjectURL(url)
}
