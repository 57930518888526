import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as admin from "admin"
import * as components from "admin/dashboard/components"
import * as typography from "typography"
import { Link } from "react-router-dom"
import * as accountedit from "accounts/edit"

export default function Display(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const params = useParams() as { aid: string }
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState({} as admin.api.accounts.FindResponse)

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => admin.api.accounts.find(params.aid, authzc.bearer(metaauthz)))
			.then(setData)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve account data</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [])

	return (
		<layouts.loading.pending loading={loading}>
			<layouts.Flex flexDirection="column" px="50px" py="20px" flex="1" gridGap="20px" {...rest}>
				{cause}
				<layouts.Flex ml="auto">
					<Link to={admin.routing.dashboard()}>{"<- Back to Dashboard"}</Link>
				</layouts.Flex>
				<Info account={data.account} />
				<Settings settings={data.account_settings} />
				<Profiles profiles={data.profiles || []} />
				<Brands brands={data.brands || []} />
			</layouts.Flex>
		</layouts.loading.pending>
	)
}

Display.defaultProps = {
	overflowY: "auto",
	height: "95vh",
}

function Info(props: { account: admin.api.account.Account }): JSX.Element {
	const { account } = props
	return (
		<layouts.Flex flexDirection="column" gridGap="5px">
			<layouts.Flex>
				<typography.h5 lineHeight="20px">Account Info</typography.h5>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Descrption:</layouts.Flex>
					<layouts.Flex flex="3">{account.description}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Domain:</layouts.Flex>
					<layouts.Flex flex="3">{account.domain}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Email:</layouts.Flex>
					<layouts.Flex flex="3">{account.email}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Created At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(account.created_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Updated At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(account.updated_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Disabled At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(account.disabled_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

function Profiles(props: { profiles: admin.api.profile.Profile[] }): JSX.Element {
	const { profiles } = props
	return (
		<layouts.Flex className="profiles" flexDirection="column" flex="1">
			<layouts.Flex>
				<typography.h5 lineHeight="20px">Account Profiles</typography.h5>
			</layouts.Flex>
			<layouts.tables.container overflow="auto" flex="1" fontSize="12px">
				<table style={{ color: layouts.theme.colors.grey.dark20 }}>
					<thead>
						<tr>
							<components.layouts.TableHead>ID</components.layouts.TableHead>
							<components.layouts.TableHead>Display</components.layouts.TableHead>
							<components.layouts.TableHead>Email</components.layouts.TableHead>
							<components.layouts.TableHead>Created At</components.layouts.TableHead>
							<components.layouts.TableHead>Disabled At</components.layouts.TableHead>
							<components.layouts.TableHead>Disabled Manually At</components.layouts.TableHead>
							<components.layouts.TableHead>Disabled Invited At</components.layouts.TableHead>
						</tr>
					</thead>
					<tbody>
						{profiles.map((p) => (
							<React.Fragment key={p.id}>
								<components.layouts.TableRow>
									<components.layouts.TableData>
										<Link to={admin.routing.profiles(p.id)}>{p.id}</Link>
									</components.layouts.TableData>
									<components.layouts.TableData>{p.display}</components.layouts.TableData>
									<components.layouts.TableData>{p.email}</components.layouts.TableData>
									<components.layouts.TableData>{layouts.tables.Timestamp(p.created_at)}</components.layouts.TableData>
									<components.layouts.TableData>{layouts.tables.Timestamp(p.disabled_at)}</components.layouts.TableData>
									<components.layouts.TableData>
										{layouts.tables.Timestamp(p.disabled_manually_at)}
									</components.layouts.TableData>
									<components.layouts.TableData>
										{layouts.tables.Timestamp(p.disabled_invited_at)}
									</components.layouts.TableData>
								</components.layouts.TableRow>
							</React.Fragment>
						))}
					</tbody>
				</table>
			</layouts.tables.container>
		</layouts.Flex>
	)
}

function Brands(props: { brands: admin.api.brand.Brand[] }): JSX.Element {
	const { brands } = props
	return (
		<layouts.Flex className="brands" flexDirection="column" flex="1">
			<layouts.Flex>
				<typography.h5 lineHeight="20px">Account Brands</typography.h5>
			</layouts.Flex>
			<layouts.tables.container overflow="auto" flex="1" fontSize="12px">
				<table style={{ color: layouts.theme.colors.grey.dark20 }}>
					<thead>
						<tr>
							<components.layouts.TableHead>ID</components.layouts.TableHead>
							<components.layouts.TableHead>Description</components.layouts.TableHead>
							<components.layouts.TableHead>Domain</components.layouts.TableHead>
							<components.layouts.TableHead>Created At</components.layouts.TableHead>
							<components.layouts.TableHead>Cloned At</components.layouts.TableHead>
						</tr>
					</thead>
					<tbody>
						{brands.map((b) => (
							<React.Fragment key={b.id}>
								<components.layouts.TableRow>
									<components.layouts.TableData>
										<Link to={admin.routing.brands(b.id)}>{b.id}</Link>
									</components.layouts.TableData>
									<components.layouts.TableData>{b.description}</components.layouts.TableData>
									<components.layouts.TableData className={components.layouts.TextOverflow} title={b.domain}>
										{b.domain}
									</components.layouts.TableData>
									<components.layouts.TableData>{layouts.tables.Timestamp(b.created_at)}</components.layouts.TableData>
									<components.layouts.TableData>{layouts.tables.Timestamp(b.cloned_at)}</components.layouts.TableData>
								</components.layouts.TableRow>
							</React.Fragment>
						))}
					</tbody>
				</table>
			</layouts.tables.container>
		</layouts.Flex>
	)
}

function Settings(props: { settings: admin.api.AccountSettings }): JSX.Element {
	const { settings } = props
	return (
		<layouts.forms.Group flex="1" flexDirection="column">
			<layouts.Flex flexDirection="column" flex="1">
				<typography.h3
					color={layouts.theme.colors.grey.dark50}
					letterSpacing="unset"
					fontWeight="700"
					textAlign="left"
					lineHeight="30px"
				>
					Scoring and Display Settings
				</typography.h3>
			</layouts.Flex>
			<layouts.Flex
				flex="1"
				flexDirection="column"
				fontSize="14px"
				color={layouts.theme.colors.grey.dark50}
				className={accountedit.styledInputs}
			>
				<accountedit.AggregateScore current={settings} onChange={() => undefined} {...accountedit.subContFlexProps} />
				<accountedit.IdentifyModelsChecked
					current={settings}
					onChange={() => undefined}
					{...accountedit.subContFlexProps}
				/>
				<accountedit.StyleModels current={settings} onChange={() => undefined} {...accountedit.subContFlexProps} />
				<accountedit.BrandSafetyModels
					current={settings}
					onChange={() => undefined}
					{...accountedit.subContFlexProps}
				/>
				<accountedit.LegalAndComplianceModels
					current={settings}
					onChange={() => undefined}
					{...accountedit.subContFlexProps}
				/>
			</layouts.Flex>
		</layouts.forms.Group>
	)
}
