import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as admin from "admin"
import * as typography from "typography"
import { Link } from "react-router-dom"
import * as inputs from "inputs"
import * as profiles from "profiles"

export default function Display(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const params = useParams() as { pid: string }
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState({} as admin.api.profiles.FindResponse)

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => admin.api.profiles.find(params.pid, authzc.bearer(metaauthz)))
			.then(setData)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve profile data</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [])

	return (
		<layouts.loading.pending loading={loading}>
			<layouts.Flex flexDirection="column" px="50px" py="20px" flex="1" gridGap="20px" {...rest}>
				{cause}
				<layouts.Flex ml="auto">
					<Link to={admin.routing.dashboard()}>{"<- Back to Dashboard"}</Link>
				</layouts.Flex>
				<Info profile={data.profile} />
				<Settings data={data} />
			</layouts.Flex>
		</layouts.loading.pending>
	)
}

Display.defaultProps = {
	overflowY: "auto",
	height: "95vh",
}

function Info(props: { profile: admin.api.profile.Profile }): JSX.Element {
	const { profile } = props
	return (
		<layouts.Flex flexDirection="column" gridGap="5px">
			<layouts.Flex>
				<typography.h5 lineHeight="20px">Profile Info</typography.h5>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Display:</layouts.Flex>
					<layouts.Flex flex="3">{profile.display}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Account ID:</layouts.Flex>
					<layouts.Flex flex="3">
						<Link to={admin.routing.accounts(profile.account_id)}>{profile.account_id}</Link>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Email:</layouts.Flex>
					<layouts.Flex flex="3">{profile.email}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Created At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(profile.created_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Updated At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(profile.updated_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Disabled At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(profile.disabled_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}

function Settings(props: { data: admin.api.profiles.FindResponse }): JSX.Element {
	const { data } = props

	const current = admin.api.profiles.settings_zero(data.settings)
	const profile = data.profile

	return (
		<layouts.Flex fontSize="12px" color={layouts.theme.colors.grey.dark50} flexDirection="column">
			<layouts.Flex mt="5px" mb="10px" fontWeight="600">
				Permissions:
			</layouts.Flex>
			<layouts.containers.grid
				width="100%"
				justifyContent="center"
				display="grid"
				gridGap="2px"
				gridTemplateColumns="repeat(2, 1fr);"
				gridTemplateRows="min-content"
			>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={profiles.invited(profile)} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Login
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={current.usermanagement} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Manage Settings
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={current.brandguard_upload} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Upload & Score Assets
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={current.brandguard_review} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Review Assets
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={current.brandguard_train} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Train System
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<inputs.Checkbox alignItems="flex-start" checked={current.brandgpt_use} onChange={() => {}}>
						<layouts.forms.Label my="unset" ml="10px">
							Use BrandGPT
						</layouts.forms.Label>
					</inputs.Checkbox>
				</layouts.forms.Container>
				<layouts.forms.Container>
					<layouts.Flex flexDirection="column">
						<inputs.Checkbox
							alignItems="flex-start"
							checked={current.brandguide_view || current.brandguide_edit}
							onChange={() => {}}
						>
							<layouts.forms.Label my="unset" ml="10px">
								BrandGuide Buider:
							</layouts.forms.Label>
						</inputs.Checkbox>
						<layouts.Flex flexDirection="column" ml="10px" mt="2px">
							<inputs.Checkbox
								alignItems="flex-start"
								disabled={current.brandguide_edit}
								checked={current.brandguide_view}
								onChange={() => {}}
							>
								<layouts.forms.Label my="unset" ml="10px">
									View
								</layouts.forms.Label>
							</inputs.Checkbox>
							<inputs.Checkbox alignItems="flex-start" checked={current.brandguide_edit} onChange={() => {}}>
								<layouts.forms.Label my="unset" ml="10px">
									Edit
								</layouts.forms.Label>
							</inputs.Checkbox>
						</layouts.Flex>
					</layouts.Flex>
				</layouts.forms.Container>
			</layouts.containers.grid>
		</layouts.Flex>
	)
}
