import { CancellablePromise } from "real-cancellable-promise"
import * as httpx from "httpx"
import * as metasearch from "metasearch"

import * as account from "accounts/meta.account"
import * as profile from "profiles/meta.profile"
import * as brand from "brands/account.brand"

import { AccountSettings } from "accounts/meta.account.score.settings"

import { SearchRequest, SearchResponse, PDFRequest, AssetsResponse, AccountAssets } from "./admin.dashboard_pb"
import { Result, SearchResponse as BGBSearchResponse } from "./brandguide.report_pb"
export { SearchRequest, SearchResponse, PDFRequest, AssetsResponse } from "./admin.dashboard_pb"
export { Result, SearchResponse as BGBSearchResponse } from "./brandguide.report_pb"

export { AccountSettings } from "accounts/meta.account.score.settings"

export * as metasearch from "metasearch"
export * as account from "accounts/meta.account"
export * as profile from "profiles/meta.profile"
export * as brand from "brands/account.brand"

export namespace dashboard {
	export namespace zeros {
		export function search(r: Partial<SearchRequest> = {}): SearchRequest {
			return {
				range: metasearch.dateranges.everything(),
				excluded_emails: [],
				...r,
			}
		}

		export function chart(r: Partial<SearchResponse> = {}): SearchResponse {
			return {
				next: search(),
				items: [],
				before_cnt: 0n,
				...r,
			}
		}

		export function bgb_result(r: Partial<Result> = {}): Result {
			return {
				version: "",
				brand_id: "",
				brand_description: "",
				account_id: "",
				account_email: "",
				profile_id: "",
				profile_email: "",
				created_at: "",
				...r,
			}
		}

		export function bgb_response(r: Partial<BGBSearchResponse> = {}): BGBSearchResponse {
			return {
				created: [],
				returned: [],
				saved: [],
				...r,
			}
		}

		export function pdf(r: Partial<PDFRequest> = {}): PDFRequest {
			return {
				version: "",
				brand_id: "",
				account_id: "",
				...r,
			}
		}

		export function asset(r: Partial<AccountAssets> = {}): AccountAssets {
			return {
				brand_id: "",
				account_id: "",
				cnt: 0n,
				...r,
			}
		}

		export function asset_resp(r: Partial<AssetsResponse> = {}): AssetsResponse {
			return {
				next: zeros.search(),
				items: [],
				...r,
			}
		}
	}
	//available
	export function available(...options: httpx.option[]): CancellablePromise<boolean> {
		return httpx.get<boolean>(`${httpx.urlstorage.host()}/admin/available`, {}, ...options).then((r) => !!r)
	}
	//accounts
	export function accounts(
		req: SearchRequest,
		...options: httpx.option[]
	): CancellablePromise<{ items: account.Account[] }> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/dashboard/accounts`, req, ...options)
	}
	export function accounts_chart(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
		return httpx
			.get<SearchResponse>(`${httpx.urlstorage.host()}/admin/dashboard/accounts_chart`, req, ...options)
			.then(zeros.chart)
	}
	//profiles
	export function profiles(
		req: SearchRequest,
		...options: httpx.option[]
	): CancellablePromise<{ items: profile.Profile[] }> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/dashboard/profiles`, req, ...options)
	}
	export function profiles_chart(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
		return httpx
			.get<SearchResponse>(`${httpx.urlstorage.host()}/admin/dashboard/profiles_chart`, req, ...options)
			.then(zeros.chart)
	}
	//brands
	export function brands(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<{ items: brand.Brand[] }> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/dashboard/brands`, req, ...options)
	}
	export function brands_chart(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<SearchResponse> {
		return httpx
			.get<SearchResponse>(`${httpx.urlstorage.host()}/admin/dashboard/brands_chart`, req, ...options)
			.then(zeros.chart)
	}
	//bgb
	export function bgb_report(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<BGBSearchResponse> {
		return httpx
			.get<BGBSearchResponse>(`${httpx.urlstorage.host()}/admin/dashboard/bgb_report`, req, ...options)
			.then(zeros.bgb_response)
	}
	export function get_pdf(req: PDFRequest, ...options: httpx.option[]): CancellablePromise<Response> {
		const params = new URLSearchParams(req as unknown as Record<string, string>)
		return new CancellablePromise(
			new Promise((resolve) =>
				resolve(httpx._fetch(`${httpx.urlstorage.host()}/admin/dashboard/bgb_pdf?${params.toString()}`, ...options)),
			),
			() => {},
		)
	}
	//assets
	//images
	export function img_uploads(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<AssetsResponse> {
		return httpx
			.get<AssetsResponse>(`${httpx.urlstorage.host()}/admin/dashboard/image_uploads`, req, ...options)
			.then((r) => zeros.asset_resp({ items: (r.items || []).map(zeros.asset) }))
	}
	export function img_trained(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<AssetsResponse> {
		return httpx
			.get<AssetsResponse>(`${httpx.urlstorage.host()}/admin/dashboard/image_trained`, req, ...options)
			.then((r) => zeros.asset_resp({ items: (r.items || []).map(zeros.asset) }))
	}
	//texts
	export function text_uploads(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<AssetsResponse> {
		return httpx
			.get<AssetsResponse>(`${httpx.urlstorage.host()}/admin/dashboard/text_uploads`, req, ...options)
			.then((r) => zeros.asset_resp({ items: (r.items || []).map(zeros.asset) }))
	}
	export function text_trained(req: SearchRequest, ...options: httpx.option[]): CancellablePromise<AssetsResponse> {
		return httpx
			.get<AssetsResponse>(`${httpx.urlstorage.host()}/admin/dashboard/text_trained`, req, ...options)
			.then((r) => zeros.asset_resp({ items: (r.items || []).map(zeros.asset) }))
	}
}

export namespace accounts {
	export interface FindResponse {
		account: account.Account
		account_settings: AccountSettings
		profiles: profile.Profile[]
		brands: brand.Brand[]
	}

	export function find(aid: string, ...options: httpx.option[]): CancellablePromise<FindResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/accounts/${aid}`, {}, ...options)
	}
}

export namespace profiles {
	export interface ProfileSettings {
		usermanagement: boolean
		brandguard_upload: boolean
		brandguard_review: boolean
		brandguard_train: boolean
		adpool_management: boolean
		brandgpt_use: boolean
		brandguide_view: boolean
		brandguide_edit: boolean
	}
	export interface FindResponse {
		profile: profile.Profile
		settings: ProfileSettings
	}

	export function settings_zero(r: Partial<ProfileSettings> = {}): ProfileSettings {
		return {
			usermanagement: false,
			brandguard_upload: false,
			brandguard_review: false,
			brandguard_train: false,
			adpool_management: false,
			brandgpt_use: false,
			brandguide_view: false,
			brandguide_edit: false,
			...r,
		}
	}

	export function find(aid: string, ...options: httpx.option[]): CancellablePromise<FindResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/profiles/${aid}`, {}, ...options)
	}
}

export namespace brands {
	export interface FindResponse {
		brand: brand.Brand
	}

	export function find(aid: string, ...options: httpx.option[]): CancellablePromise<FindResponse> {
		return httpx.get(`${httpx.urlstorage.host()}/admin/brands/${aid}`, {}, ...options)
	}
}
