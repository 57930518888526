import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as admin from "admin"
import * as typography from "typography"
import { Link } from "react-router-dom"

export default function Display(props: layouts.containers.FlexProps): JSX.Element {
	const { ...rest } = props
	const params = useParams() as { bid: string }
	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState({} as admin.api.brands.FindResponse)

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => admin.api.brands.find(params.bid, authzc.bearer(metaauthz)))
			.then(setData)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve brand data</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [])

	return (
		<layouts.loading.pending loading={loading}>
			<layouts.Flex flexDirection="column" px="50px" py="20px" flex="1" gridGap="20px" {...rest}>
				{cause}
				<layouts.Flex ml="auto">
					<Link to={admin.routing.dashboard()}>{"<- Back to Dashboard"}</Link>
				</layouts.Flex>
				<Info brand={data.brand} />
			</layouts.Flex>
		</layouts.loading.pending>
	)
}

Display.defaultProps = {
	overflowY: "auto",
	height: "95vh",
}

function Info(props: { brand: admin.api.brand.Brand }): JSX.Element {
	const { brand } = props
	return (
		<layouts.Flex flexDirection="column" gridGap="5px">
			<layouts.Flex>
				<typography.h5 lineHeight="20px">Brand Info</typography.h5>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Descrption:</layouts.Flex>
					<layouts.Flex flex="3">{brand.description}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Domain:</layouts.Flex>
					<layouts.Flex flex="3">{brand.domain}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Account ID</layouts.Flex>
					<layouts.Flex flex="3">
						<Link to={admin.routing.accounts(brand.account_id)}>{brand.account_id}</Link>
					</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Threshold:</layouts.Flex>
					<layouts.Flex flex="3">{brand.brandguard_threshold}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Created At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(brand.created_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Updated At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(brand.updated_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
			<layouts.Flex flexDirection="column">
				<layouts.Flex>
					<layouts.Flex flex="1">Cloned At:</layouts.Flex>
					<layouts.Flex flex="3">{layouts.tables.Timestamp(brand.cloned_at)}</layouts.Flex>
				</layouts.Flex>
			</layouts.Flex>
		</layouts.Flex>
	)
}
