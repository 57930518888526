import React, { useEffect, useState } from "react"
import * as layouts from "layouts"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as api from "admin/api"
import * as components from "admin/dashboard/components"

function parsedInitial(proto: Partial<components.parsed> = {}): components.parsed {
	return {
		base: components.chart.parsedMetricZero({
			title: "New Brands",
			chart: components.chart.parsedChartZero({
				axisID: "y",
				borderColor: layouts.theme.colors.charts.green.base,
				backgroundColor: layouts.theme.colors.charts.green.alpha,
			}),
		}),
		growth: components.chart.parsedMetricZero({
			title: "New Brands Growth",
			chart: components.chart.parsedChartZero({
				axisID: "y",
				borderColor: layouts.theme.colors.charts.green.base,
				backgroundColor: layouts.theme.colors.charts.green.alpha,
			}),
		}),
		...proto,
	}
}

interface props extends layouts.containers.FlexProps {
	req: api.SearchRequest
}

export default function Charts(props: props): JSX.Element {
	const { req, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState(parsedInitial({}))

	useEffect(() => {
		if (req.range?.oldest === components.neginfiso || req.range?.newest === components.infiso) return
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => api.dashboard.brands_chart(req, authzc.bearer(metaauthz)))
			.then((r) => setData(components.chartParse(r, parsedInitial())))
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve brand chart data</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [req])

	return (
		<layouts.loading.pending loading={loading} {...rest}>
			{cause}
			<components.layouts.ChartContainer>
				<components.chart.Chart labels={data.base.chart.labels} dataset={[data.base]} />
			</components.layouts.ChartContainer>
			<components.layouts.ChartContainer>
				<components.chart.Chart labels={data.growth.chart.labels} dataset={[data.growth]} />
			</components.layouts.ChartContainer>
		</layouts.loading.pending>
	)
}
