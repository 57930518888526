import React, { useState, useEffect, useContext } from "react"
import * as api from "./api"
import * as metasearch from "metasearch"
import * as components from "./dashboard/components"

export const excluded_emails = ["novacloud.ai", "brandguard.ai"]

export const Tabs = {
	ACCOUNTS: 0,
	PROFILES: 1,
	BRANDS: 2,
	BGB: 3,
	ASSETS: 4,
}

export const req_default = api.dashboard.zeros.search({
	range: metasearch.dateranges.days(30),
	excluded_emails: [],
})

export interface cached {
	req: api.SearchRequest
	focused: number
	update(upd: Partial<cached>): void
}

export function zero(c: Partial<cached> = {}): cached {
	return {
		req: req_default,
		focused: Tabs.ACCOUNTS,
		update: (upd: Partial<cached>) => {},
		...c,
	}
}

export const Context = React.createContext(zero())
export const Provider = Context.Provider

export function useCached(): cached {
	return useContext(Context)
}

export function Node(props: React.PropsWithChildren<unknown>): JSX.Element {
	const [cache, setChache] = useState(zero())

	const ctx = {
		...cache,
		update(d: Partial<cached>) {
			setChache({ ...cache, ...d })
		},
	}

	useEffect(() => {
		if (cache.req.range?.oldest === components.neginfiso || cache.req.range?.newest === components.infiso) return
	}, [])

	return <Provider value={ctx}>{props.children}</Provider>
}
