import * as layouts from "layouts"
import * as caching from "admin/cache"

interface props {
	onChange(upd: number): void
}

export default function Tab(props: props): JSX.Element {
	const { onChange } = props
	return (
		<layouts.containers.flex flexDirection="row" textAlign="left" boxShadow="none" mx="20px">
			<layouts.containers.flex flexDirection="row" flex="1" borderBottom={layouts.theme.borders.grey.dark50alphamedium}>
				<layouts.containers.flex
					p="5px"
					flexDirection="row"
					borderBottom={`2px solid ${layouts.theme.colors.blue.blue}`}
				>
					<layouts.containers.flex fontSize="14px" fontWeight="700" color={layouts.theme.colors.grey.dark50}>
						Accounts
					</layouts.containers.flex>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex p="5px" flexDirection="row" onClick={() => onChange(caching.Tabs.PROFILES)}>
					<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
						Profiles
					</layouts.containers.span>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex p="5px" flexDirection="row" onClick={() => onChange(caching.Tabs.BRANDS)}>
					<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
						Brands
					</layouts.containers.span>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex p="5px" flexDirection="row" onClick={() => onChange(caching.Tabs.BGB)}>
					<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
						BrandGuide Builder
					</layouts.containers.span>
				</layouts.containers.flex>
				<layouts.containers.flex mx="20px" my="4px" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
				<layouts.containers.flex p="5px" flexDirection="row" onClick={() => onChange(caching.Tabs.ASSETS)}>
					<layouts.containers.span opacity="0.5" color={layouts.theme.colors.grey.dark50} fontSize="14px">
						Assets
					</layouts.containers.span>
				</layouts.containers.flex>
			</layouts.containers.flex>
		</layouts.containers.flex>
	)
}
