import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as layouts from "layouts"
import * as admin from "admin"
import * as components from "admin/dashboard/components"
import * as typography from "typography"
import { Link } from "react-router-dom"

interface props extends layouts.containers.FlexProps {
	req: admin.api.SearchRequest
}

export default function List(props: props): JSX.Element {
	const { req, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState({ next: req, items: [] } as admin.api.AssetsResponse)

	useEffect(() => {
		if (req.range?.oldest === components.neginfiso || req.range?.newest === components.infiso) return
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => admin.api.dashboard.text_trained(req, authzc.bearer(metaauthz)))
			.then(setData)
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve trained texts</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [req])

	return (
		<layouts.Flex className="img-uploaded" flexDirection="column" flex="1" {...rest}>
			<layouts.Flex px="10px">
				<typography.h5 lineHeight="20px">Trained Texts</typography.h5>
			</layouts.Flex>
			<layouts.tables.container overflow="auto" flex="1" fontSize="12px">
				<errors.overlay styled cause={cause}>
					<layouts.loading.pending loading={loading}>
						<table style={{ color: layouts.theme.colors.grey.dark20 }}>
							<thead>
								<tr>
									<components.layouts.TableHead>AccountID</components.layouts.TableHead>
									<components.layouts.TableHead>Text Trained Count</components.layouts.TableHead>
								</tr>
							</thead>
							<tbody>
								{data.items.map((a, i) => (
									<React.Fragment key={i}>
										<components.layouts.TableRow>
											<components.layouts.TableData>
												<Link to={admin.routing.accounts(a.account_id)}>{a.account_id}</Link>
											</components.layouts.TableData>
											<components.layouts.TableData>{Number(a.cnt)}</components.layouts.TableData>
										</components.layouts.TableRow>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</layouts.loading.pending>
				</errors.overlay>
			</layouts.tables.container>
		</layouts.Flex>
	)
}
