/* eslint-disable */
// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,add_pb_suffix,use_proto_field_name,ts_nocheck,eslint_disable
// @generated from protobuf file "admin.dashboard.proto" (package "admin.dashboard", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { DateRange } from "./meta.search_pb";
/**
 * @generated from protobuf message admin.dashboard.Datum
 */
export interface Datum {
    /**
     * @generated from protobuf field: meta.DateRange range = 1;
     */
    range?: DateRange;
    /**
     * @generated from protobuf field: int64 cnt = 2;
     */
    cnt: bigint;
}
/**
 * @generated from protobuf message admin.dashboard.SearchRequest
 */
export interface SearchRequest {
    /**
     * @generated from protobuf field: meta.DateRange range = 1;
     */
    range?: DateRange;
    /**
     * @generated from protobuf field: repeated string excluded_emails = 2 [json_name = "excluded_emails"];
     */
    excluded_emails: string[];
}
/**
 * @generated from protobuf message admin.dashboard.SearchResponse
 */
export interface SearchResponse {
    /**
     * @generated from protobuf field: admin.dashboard.SearchRequest next = 1;
     */
    next?: SearchRequest;
    /**
     * @generated from protobuf field: repeated admin.dashboard.Datum items = 2;
     */
    items: Datum[];
    /**
     * @generated from protobuf field: int64 before_cnt = 3 [json_name = "before_cnt"];
     */
    before_cnt: bigint;
}
/**
 * @generated from protobuf message admin.dashboard.PDFRequest
 */
export interface PDFRequest {
    /**
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * @generated from protobuf field: string account_id = 2 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 3 [json_name = "brand_id"];
     */
    brand_id: string;
}
/**
 * @generated from protobuf message admin.dashboard.AccountAssets
 */
export interface AccountAssets {
    /**
     * @generated from protobuf field: string account_id = 1 [json_name = "account_id"];
     */
    account_id: string;
    /**
     * @generated from protobuf field: string brand_id = 2 [json_name = "brand_id"];
     */
    brand_id: string;
    /**
     * @generated from protobuf field: int64 cnt = 3;
     */
    cnt: bigint;
}
/**
 * @generated from protobuf message admin.dashboard.AssetsResponse
 */
export interface AssetsResponse {
    /**
     * @generated from protobuf field: admin.dashboard.SearchRequest next = 1;
     */
    next?: SearchRequest;
    /**
     * @generated from protobuf field: repeated admin.dashboard.AccountAssets items = 2;
     */
    items: AccountAssets[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Datum$Type extends MessageType<Datum> {
    constructor() {
        super("admin.dashboard.Datum", [
            { no: 1, name: "range", kind: "message", T: () => DateRange },
            { no: 2, name: "cnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Datum>): Datum {
        const message = { cnt: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Datum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Datum): Datum {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.DateRange range */ 1:
                    message.range = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* int64 cnt */ 2:
                    message.cnt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Datum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.DateRange range = 1; */
        if (message.range)
            DateRange.internalBinaryWrite(message.range, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 cnt = 2; */
        if (message.cnt !== 0n)
            writer.tag(2, WireType.Varint).int64(message.cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.Datum
 */
export const Datum = new Datum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchRequest$Type extends MessageType<SearchRequest> {
    constructor() {
        super("admin.dashboard.SearchRequest", [
            { no: 1, name: "range", kind: "message", T: () => DateRange },
            { no: 2, name: "excluded_emails", kind: "scalar", localName: "excluded_emails", jsonName: "excluded_emails", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SearchRequest>): SearchRequest {
        const message = { excluded_emails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchRequest): SearchRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* meta.DateRange range */ 1:
                    message.range = DateRange.internalBinaryRead(reader, reader.uint32(), options, message.range);
                    break;
                case /* repeated string excluded_emails = 2 [json_name = "excluded_emails"];*/ 2:
                    message.excluded_emails.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* meta.DateRange range = 1; */
        if (message.range)
            DateRange.internalBinaryWrite(message.range, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string excluded_emails = 2 [json_name = "excluded_emails"]; */
        for (let i = 0; i < message.excluded_emails.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.excluded_emails[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.SearchRequest
 */
export const SearchRequest = new SearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchResponse$Type extends MessageType<SearchResponse> {
    constructor() {
        super("admin.dashboard.SearchResponse", [
            { no: 1, name: "next", kind: "message", T: () => SearchRequest },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Datum },
            { no: 3, name: "before_cnt", kind: "scalar", localName: "before_cnt", jsonName: "before_cnt", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<SearchResponse>): SearchResponse {
        const message = { items: [], before_cnt: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SearchResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchResponse): SearchResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* admin.dashboard.SearchRequest next */ 1:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                case /* repeated admin.dashboard.Datum items */ 2:
                    message.items.push(Datum.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 before_cnt = 3 [json_name = "before_cnt"];*/ 3:
                    message.before_cnt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* admin.dashboard.SearchRequest next = 1; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated admin.dashboard.Datum items = 2; */
        for (let i = 0; i < message.items.length; i++)
            Datum.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 before_cnt = 3 [json_name = "before_cnt"]; */
        if (message.before_cnt !== 0n)
            writer.tag(3, WireType.Varint).int64(message.before_cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.SearchResponse
 */
export const SearchResponse = new SearchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PDFRequest$Type extends MessageType<PDFRequest> {
    constructor() {
        super("admin.dashboard.PDFRequest", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PDFRequest>): PDFRequest {
        const message = { version: "", account_id: "", brand_id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PDFRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PDFRequest): PDFRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version */ 1:
                    message.version = reader.string();
                    break;
                case /* string account_id = 2 [json_name = "account_id"];*/ 2:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 3 [json_name = "brand_id"];*/ 3:
                    message.brand_id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PDFRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version = 1; */
        if (message.version !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.version);
        /* string account_id = 2 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 3 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brand_id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.PDFRequest
 */
export const PDFRequest = new PDFRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountAssets$Type extends MessageType<AccountAssets> {
    constructor() {
        super("admin.dashboard.AccountAssets", [
            { no: 1, name: "account_id", kind: "scalar", localName: "account_id", jsonName: "account_id", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "brand_id", kind: "scalar", localName: "brand_id", jsonName: "brand_id", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<AccountAssets>): AccountAssets {
        const message = { account_id: "", brand_id: "", cnt: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountAssets>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountAssets): AccountAssets {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string account_id = 1 [json_name = "account_id"];*/ 1:
                    message.account_id = reader.string();
                    break;
                case /* string brand_id = 2 [json_name = "brand_id"];*/ 2:
                    message.brand_id = reader.string();
                    break;
                case /* int64 cnt */ 3:
                    message.cnt = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountAssets, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string account_id = 1 [json_name = "account_id"]; */
        if (message.account_id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.account_id);
        /* string brand_id = 2 [json_name = "brand_id"]; */
        if (message.brand_id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.brand_id);
        /* int64 cnt = 3; */
        if (message.cnt !== 0n)
            writer.tag(3, WireType.Varint).int64(message.cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.AccountAssets
 */
export const AccountAssets = new AccountAssets$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetsResponse$Type extends MessageType<AssetsResponse> {
    constructor() {
        super("admin.dashboard.AssetsResponse", [
            { no: 1, name: "next", kind: "message", T: () => SearchRequest },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccountAssets }
        ]);
    }
    create(value?: PartialMessage<AssetsResponse>): AssetsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssetsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetsResponse): AssetsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* admin.dashboard.SearchRequest next */ 1:
                    message.next = SearchRequest.internalBinaryRead(reader, reader.uint32(), options, message.next);
                    break;
                case /* repeated admin.dashboard.AccountAssets items */ 2:
                    message.items.push(AccountAssets.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* admin.dashboard.SearchRequest next = 1; */
        if (message.next)
            SearchRequest.internalBinaryWrite(message.next, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated admin.dashboard.AccountAssets items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AccountAssets.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message admin.dashboard.AssetsResponse
 */
export const AssetsResponse = new AssetsResponse$Type();
