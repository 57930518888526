import React, { useEffect, useState } from "react"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as errors from "errors"
import * as layouts from "layouts"
import * as admin from "admin"
import * as components from "admin/dashboard/components"
import * as typography from "typography"
import { Link } from "react-router-dom"

interface props extends layouts.containers.FlexProps {
	req: admin.api.SearchRequest
}

export default function List(props: props): JSX.Element {
	const { req, ...rest } = props

	const metaauthz = authzc.useCache((cached) => cached.meta)
	const [loading, setLoading] = useState(true)
	const [cause, setCause] = useState(undefined as JSX.Element | undefined)

	const [data, setData] = useState<admin.api.brand.Brand[]>([])

	useEffect(() => {
		if (req.range?.oldest === components.neginfiso || req.range?.newest === components.infiso) return
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => admin.api.dashboard.brands(req, authzc.bearer(metaauthz)))
			.then((r) => setData(r.items || []))
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				setCause(
					<errors.Inline p="5px" mb="unset">
						<errors.Textual onClick={() => setCause(undefined)}>unable to retrieve brand list</errors.Textual>
					</errors.Inline>,
				)
			})
			.finally(() => setLoading(false))
		return pending.cancel
	}, [req])

	return (
		<layouts.Flex className="brands" flexDirection="column" flex="1" {...rest}>
			<layouts.Flex px="10px">
				<typography.h5 lineHeight="20px">Last 20 created</typography.h5>
			</layouts.Flex>
			<layouts.tables.container overflow="auto" flex="1">
				<errors.overlay styled cause={cause}>
					<layouts.loading.pending loading={loading}>
						<table style={{ color: layouts.theme.colors.grey.dark20 }}>
							<thead>
								<tr>
									<components.layouts.TableHead>ID</components.layouts.TableHead>
									<components.layouts.TableHead>Description</components.layouts.TableHead>
									<components.layouts.TableHead>Domain</components.layouts.TableHead>
									<components.layouts.TableHead>AccountID</components.layouts.TableHead>
									<components.layouts.TableHead>Created At</components.layouts.TableHead>
								</tr>
							</thead>
							<tbody>
								{data.map((b) => (
									<React.Fragment key={b.id}>
										<components.layouts.TableRow>
											<components.layouts.TableData>
												<Link to={admin.routing.brands(b.id)}>{b.id}</Link>
											</components.layouts.TableData>
											<components.layouts.TableData>{b.description}</components.layouts.TableData>
											<components.layouts.TableData className={components.layouts.TextOverflow} title={b.domain}>
												{b.domain}
											</components.layouts.TableData>
											<components.layouts.TableData>
												<Link to={admin.routing.accounts(b.account_id)}>{b.account_id}</Link>
											</components.layouts.TableData>
											<components.layouts.TableData>
												{layouts.tables.Timestamp(b.created_at)}
											</components.layouts.TableData>
										</components.layouts.TableRow>
									</React.Fragment>
								))}
							</tbody>
						</table>
					</layouts.loading.pending>
				</errors.overlay>
			</layouts.tables.container>
		</layouts.Flex>
	)
}
