import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as api from "./api"
import * as httpx from "httpx"
import * as authzc from "authzcached"
import * as layouts from "layouts"

export function Node(props: React.PropsWithChildren<unknown>): JSX.Element {
	const navigate = useNavigate()
	const metaauthz = authzc.useCache((cached) => cached.meta)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const retry = httpx.autoretry()
		setLoading(true)
		const pending = retry
			.wrap(() => api.dashboard.available(authzc.bearer(metaauthz)))
			.then((r) => {
				if (r) {
					setLoading(false)
					return
				}
				navigate("/")
			})
			.catch(httpx.errors.cancellation(console.warn))
			.catch((c: unknown) => {
				console.warn(c)
				navigate("/")
			})
		return pending.cancel
	}, [])

	return <layouts.loading.pending loading={loading}>{props.children}</layouts.loading.pending>
}
