import * as layouts from "layouts"
import { PropsWithChildren } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/css"

export function ChartContainer(props: PropsWithChildren<layouts.containers.ContainerProps>): JSX.Element {
	const { children, ...rest } = props
	return <layouts.containers.box {...rest}>{children}</layouts.containers.box>
}

ChartContainer.defaultProps = {
	display: "block",
	position: "relative",
	height: "30vh",
	width: "80vw",
	background: layouts.theme.colors.white,
	m: "auto",
}

export const TableHead = styled.th`
	vertical-align: middle;
`
export const TableRow = styled.tr`
	cursor: default;
	&:hover {
		color: ${layouts.theme.colors.grey.lightest};
		background: ${layouts.theme.colors.blue.blue};
	}
`
export const TableData = styled.td`
	vertical-align: middle;
`

export const TextOverflow = css`
	text-overflow: ellipsis;
	max-width: 250px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
`
