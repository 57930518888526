import * as layouts from "layouts"
import * as api from "admin/api"
import { ImgUpload, ImgTrained, TextUpload, TextTrained } from "."

interface props extends layouts.containers.FlexProps {
	req: api.SearchRequest
}

export default function Display(props: props): JSX.Element {
	const { req, ...rest } = props

	return (
		<layouts.Flex flexDirection="column" flex="1" color={layouts.theme.colors.grey.dark10} {...rest}>
			<layouts.Flex flexDirection="column" flex="1" gridGap="10px" justifyContent="center" m="20px">
				<ImgUpload req={req} />
				<TextUpload req={req} />
				<ImgTrained req={req} />
				<TextTrained req={req} />
			</layouts.Flex>
		</layouts.Flex>
	)
}

Display.defaultProps = {
	overflowY: "auto",
	height: "100%",
}
