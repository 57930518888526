import { chart } from "metrics"
import * as timex from "timex"
import * as api from "admin/api"

export { default as Accounts } from "./accounts/display"
export { default as AccountsTab } from "./accounts/tab"
export { default as Profiles } from "./profiles/display"
export { default as ProfilesTab } from "./profiles/tab"
export { default as Brands } from "./brands/display"
export { default as BrandsTab } from "./brands/tab"
export { default as BGB } from "./bgb/display"
export { default as BGBTab } from "./bgb/tab"
export { default as Assets } from "./assets/display"
export { default as AssetsTab } from "./assets/tab"

export * as chart from "./chart"
export * as layouts from "./layouts"

export const getDaysArray = function (startDate: Date, endDate: Date) {
	for (var result = [], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate() + 1)) {
		result.push(new Date(d))
	}
	return result
}

export const infiso = timex.infinity().toISO()
export const neginfiso = timex.negInfinity().toISO()

export interface parsed {
	base: chart.metric
	growth: chart.metric
}

export function chartParse(r: api.SearchResponse, data: parsed): parsed {
	let totalCnt = Number(r.before_cnt || 0)

	const itemsByDay: { [key: string]: number } = r.items.reduce((acc: { [key: string]: number }, item) => {
		const day = new Date(item.range!.oldest).toISOString().slice(0, 10)
		acc[day] = Number(BigInt.asIntN(32, BigInt(item.cnt || 0)))
		return acc
	}, {})

	getDaysArray(new Date(r.next?.range?.oldest!), new Date(r.next?.range?.newest!)).forEach((i) => {
		const day = i.toISOString().slice(0, 10)
		const cnt = itemsByDay[day] || 0

		data.base.chart.labels.push(day)
		data.base.chart.data[day] = cnt

		data.growth.chart.labels.push(day)
		totalCnt += cnt
		data.growth.chart.data[day] = totalCnt
	})

	data.base.val = Object.values(data.base.chart.data).reduce((a, b) => a + b, 0)
	data.growth.val = totalCnt

	return data
}
