import * as components from "./components"
import * as layouts from "layouts"
import * as um from "meta/usermanagement"
import * as icons from "icons"
import * as inputs from "inputs"
import * as timex from "timex"
import * as caching from "admin/cache"

export default function Display(): JSX.Element {
	const context = caching.useCached()

	return (
		<layouts.Flex flexDirection="column" flex="1" color={layouts.theme.colors.grey.dark10}>
			<layouts.Flex my="20px" justifyContent="end">
				<layouts.Flex alignItems="center" p="10px" background={layouts.theme.colors.white} borderRadius="10px">
					<icons.Calendar />
					<inputs.calendars.DateRangeOneDatepicker
						className={um.layouts.StyledDatePicker}
						placeholderText="Date Range"
						minStartDate={new Date(timex.from.iso(context.req.range?.oldest!).minus(timex.duration.days(180)).toISO())}
						maxEndDate={new Date()}
						startDate={new Date(context.req.range?.oldest || components.neginfiso)}
						endDate={new Date(context.req.range?.newest || components.infiso)}
						changeStart={(d: Date) => {
							context.update({
								...context,
								req: {
									...context.req,
									range: { oldest: timex.from.date(d).toISO(), newest: components.infiso },
								},
							})
						}}
						changeEnd={(d: Date) => {
							context.update({
								...context,
								req: {
									...context.req,
									range: { oldest: context.req.range?.oldest!, newest: timex.from.date(d).toISO() },
								},
							})
						}}
					/>
					<layouts.containers.flex mx="20px" height="80%" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
					<layouts.tooltips.default text="Ignore brandguard.ai & novacloud.ai signs" left="-100%">
						<inputs.Checkbox
							checked={context.req.excluded_emails.length > 0}
							onChange={() =>
								context.update({
									...context,
									req: {
										...context.req,
										excluded_emails: context.req.excluded_emails.length > 0 ? [] : caching.excluded_emails,
									},
								})
							}
							alignItems="center"
							gridGap="10px"
							mb="unset"
							flex="unset"
							fontSize="14px"
						>
							Exclude Internal
						</inputs.Checkbox>
					</layouts.tooltips.default>
					<layouts.containers.flex mx="20px" height="80%" borderRight={layouts.theme.borders.grey.dark50alphamedium} />
					<layouts.containers.flex pr="20px" onClick={() => context.update({ ...context, req: caching.req_default })}>
						<icons.searches.Clear />
					</layouts.containers.flex>
				</layouts.Flex>
			</layouts.Flex>
			{context.focused === caching.Tabs.ACCOUNTS && (
				<>
					<components.AccountsTab onChange={(upd) => context.update({ ...context, focused: upd })} />
					<components.Accounts req={context.req} />
				</>
			)}
			{context.focused === caching.Tabs.PROFILES && (
				<>
					<components.ProfilesTab onChange={(upd) => context.update({ ...context, focused: upd })} />
					<components.Profiles req={context.req} />
				</>
			)}
			{context.focused === caching.Tabs.BRANDS && (
				<>
					<components.BrandsTab onChange={(upd) => context.update({ ...context, focused: upd })} />
					<components.Brands req={context.req} />
				</>
			)}
			{context.focused === caching.Tabs.BGB && (
				<>
					<components.BGBTab onChange={(upd) => context.update({ ...context, focused: upd })} />
					<components.BGB req={context.req} />
				</>
			)}
			{context.focused === caching.Tabs.ASSETS && (
				<>
					<components.AssetsTab onChange={(upd) => context.update({ ...context, focused: upd })} />
					<components.Assets req={context.req} />
				</>
			)}
		</layouts.Flex>
	)
}
